import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';

import { sendPasswordResetEmail } from "firebase/auth";
import { auth, storage, ref, getDownloadURL } from '../../../config/firebase-config';

import Meta from '../../common/Meta';
import validation from '../../../config/validation';
import './Forgot_password.css';

const Forgot_password = (props)=>{  

    const metaData = {
		meta_title			: 'Forgot password',
		meta_description	: '',
		meta_keywords		: '',
	} 

	const __data = {		
		email: ''
	}
	const __errors = {	
		email: ''
	}

	const [data,set_data]								= useState(__data) 
	const [disablebutton, set_disablebutton] 			= useState(false); 
	const [disablebuttonclass, set_disablebuttonclass]  = useState(""); 
	const [success_msg,set_success_msg] 				= useState("")  
	const [common_error,set_common_error] 				= useState("")  
	const [errors,set_errors]     						= useState(__errors)
	const [logo,set_logo] 								= useState("")  

	useEffect(()=>{
		get_firebase_assets_url()
	},[])

	const get_firebase_assets_url = ()=>{
		const logoRef = ref(storage, 'assets/upload/logo/logo.png');    
		getDownloadURL(logoRef).then( (url)=>{
			set_logo(url)
		})
	}	

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}	
	
    const validateForm = ()=>{		
		let errors       = {};  
		let isValid      = true;   		
		if(!validation.validateEmail(data.email)) {
			isValid 		       = false;
			errors.email           = 'Email is not valid!';
		}	
		set_errors(errors);	
		return isValid;		 
	}
    const handleSubmit = async(e)=>{

		e.preventDefault();        
		if(validateForm()){				
			await sendPasswordResetEmail(auth, data.email)
			.then(() => {				
				set_success_msg('Password reset email sent to your email id : '+data.email)
				set_data(__data)
			})
			.catch((error) => {
				const errorCode    = error.code;
				const errorMessage = error.message;				
				if( errorCode==='auth/user-not-found' ){
					set_errors({email:'Wrong Email'})					
				}	
			});
		}			
	}	

	
			
    return ( 
	<>
		<Meta metaData={metaData} />
		<HelmetProvider>
		<Helmet>		
		</Helmet>				
		</HelmetProvider>
		
		<main>
		<div className="container">
		<section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
		<div className="container">
		<div className="row justify-content-center">
		<div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
		<div className="d-flex justify-content-center py-4">
		<div className="logo d-flex align-items-center w-auto"> 
		{/* <img src={`${process.env.REACT_APP_STORAGE_URL}assets/img/logo.png`} alt="" />	 */}
		<img src={logo} alt="" />		
		</div>
		</div>
		<div className="card mb-3">
		<div className="card-body">
			<div className="pt-4 pb-2">
			<h5 className="card-title text-center pb-0 fs-4">Forgot Password</h5>
			<p className="text-center small">You forgot your password? Here you can easily retrieve a new password.</p>
			</div>
			{common_error &&            
            	<div className="alert alert-danger alert-dismissible fade show">
				{common_error}            
				</div> 
			}  
			{success_msg &&            
				<div className="alert alert-success alert-dismissible fade show">
				{success_msg}            
				</div>
			}  
			<form className="row g-3 needs-validation" method="post" onSubmit={handleSubmit}>
			
			<div className="col-12">
			<div className={`input-group ${errors.email ? 'error' : ''}`}>
			<input 
			type="text" 
			className="form-control" 
			placeholder="Email" 
			id="email"
			name="email" 
			value={data.email} 
			onChange={handleChange}
			autoComplete="off"/>
			</div>				
			{errors.email && 
			<div className="error-msg">{errors.email}</div>    
			}  	
			</div>

			<div className="col-12">
			<button 
			type="submit"
			className={`btn btn-primary w-100 ${disablebuttonclass}`}
			disabled={disablebutton}			
			>Request new password</button>
			</div>
			<div className="col-12">
			<p className="small mb-0"> <Link to="/login">Login</Link> </p>			
			</div>
			</form>

		</div>
		</div>		
		</div>
		</div>
		</div>
		</section>
		</div>
		</main>
		
	</>
    );
  
}

export default Forgot_password;

