import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { query, collection, doc, getDoc, getDocs, addDoc, updateDoc,orderBy} from "firebase/firestore";
import { db, storage } from "../../../config/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Portfolio' : 'Add Portfolio',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        image: '',
		name: '',
        industry_id: '',		
		service_id: '',
        technologies: '',
        short_description: '',	
		long_description:'',		
		project_date: '',  
		project_url: '',  	
		client_name: '',  
		location: '',  
		status:''
	}
	const __errors = {
		image: '',
		name: '',
        industry_id: '',		
		service_id: '',
        technologies: '',
        short_description: '',			
		project_date: '', 		
		location: '',  
		status:''
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false);   	
	const [errors,set_errors]     			   				= useState(__errors)     
    const [progresspercent, setProgresspercent]             = useState(0);
	const [isUpdated, setIsUpdated]                         = useState(false);
	const [industries,set_industries]  						= useState([])
	const [services,set_services]  							= useState([])
	const [technologiesData,set_technologiesData]  		    = useState([])
    const [imageSize, setImageSize]                         = useState({width:'',height:''});

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		getImageSize()
		if(id){
			fetchData(id)
		} 
		fetch_industries()
		fetch_services()
		fetch_technologies()       
	},[]); 	

    const fetchData = async (id)=>{
        const docRef  = doc(db, "portfolio", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			let item = docSnap.data()
			item['technologies'] = item.technologies.split(',')
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }
    const getImageSize = async ()=>{
        const docRef  = doc(db, "image_size", "E9NVYY7vKZOGTUow6wxD");
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {			
			setImageSize({
				...docSnap.data(),
				id:docSnap.id
			})			
		} 		
    }

	const fetch_industries = async ()=>{ 
		let q = query(collection(db, "industries"), orderBy('name','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 
        set_industries(result)		
    }

	const fetch_services = async ()=>{ 
		let q = query(collection(db, "services"), orderBy('title','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 		
        set_services(result)		
    }

	const fetch_technologies = async ()=>{ 
		let q = query(collection(db, "technologies"), orderBy('name','asc'));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 		
        set_technologiesData(result)		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;

		if(field_name==='technologies'){
			let selected = [];
			const checked = document.querySelectorAll('.technlg:checked'); 
			selected = Array.from(checked).map(x => x.value)
			let text = selected.join(',');			
			set_data({...data, [field_name]: selected})
		}
		else{
			set_data({...data, [field_name]: field_value})
		}
		
	}

	const upload_file = (e)=>{	
        const promise = new Promise( (resolve,reject)=>{       
            const file = e.target[0]?.files[0]
            if(!file){
                resolve('') 
            } 
            const storageRef = ref(storage, `assets/upload/portfolio/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
                        resolve(downloadURL) 
                        setProgresspercent(0);
                    });
                }
            );
        })
        return promise        
	}

	const deleteFile = (fileUrl,id)=>{
        MySwal.fire({
            title: 'Are you sure?',  
            text: 'You want to delete selected item',                 
            showCancelButton: true,                 
            cancelButtonText: 'No',
            cancelButtonColor: '#3085d6',  
            confirmButtonText: 'Yes', 
            confirmButtonColor: '#d33'
        }).then((result) => {
            
            if(result.isConfirmed) {
                const desertRef = ref(storage, fileUrl);        
                deleteObject(desertRef).then( async () => {            
                    const docRef = doc(db, "portfolio", id);                
                    await updateDoc(docRef, {
                        image: '',                
                    }); 
                    set_data({...data, ['image']: ''})                    
                    MySwal.fire({icon: 'success', text:'item deleted succsessfully', confirmButtonColor: '#3085d6'})       

                })                  
            }
                       
        })
    }

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true; 
		
		let technologies_check    = document.querySelectorAll('.technlg:checked'); 
		
        if(!data.name){
			isValid 			   = false;
			errors.name            = 'Name is required';
		}	
		if(!data.industry_id){
			isValid 			   = false;
			errors.industry_id     = 'Industry is required';
		}	
		if(!data.service_id){
			isValid 			   = false;
			errors.service_id      = 'Service is required';
		}	
		if(technologies_check.length < 1){
			isValid 			   = false;
			errors.technologies    = 'Technologies is required';
		}	
		if(!data.short_description){
			isValid 			     = false;
			errors.short_description = 'Short Description is required';
		}	
		if(!data.project_date){
			isValid 			     = false;
			errors.project_date      = 'Project Date is required';
		}	
		if(!data.location){
			isValid 			     = false;
			errors.location          = 'Location is required';
		}
		if(data.status===''){
			isValid 			   = false;
			errors.status          = 'Status is required';
		}			
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){
				
			if(id){
					set_disablebutton(true)
					const promise = upload_file(e)                    
					promise.then( async(uploadedFile)=>{  

						if(uploadedFile){
							set_data({...data, ['image']: uploadedFile})
						}  

						if(!uploadedFile && !data.image){
							set_errors({['image']: 'Image is required'})
							set_disablebutton(false)	
							return false
						}
						else{
							const docRef = doc(db, "portfolio", id);                
							await updateDoc(docRef, {
								image: (uploadedFile) ? uploadedFile : data.image,
								name:data.name,                 
								industry_id: data.industry_id,                   
								service_id: data.service_id,     
								technologies: data.technologies.join(','),    
								short_description: data.short_description,  
								long_description: data.long_description, 							
								project_date: data.project_date, 
								project_url: data.project_url, 
								client_name: data.client_name, 
								location: data.location, 
								status: parseInt(data.status), 
								modified_date: new Date(),        
							}); 						

							setIsUpdated(true) 
							set_disablebutton(false)							
							MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
						}
						
					}); 			
				
			}
			else{

					set_disablebutton(true)
					const promise = upload_file(e)                    
					promise.then( async(uploadedFile)=>{                
						if(uploadedFile){
							set_data({...data, ['image']: uploadedFile})
						} 
						
						if(!uploadedFile && !data.image){
							set_errors({['image']: 'Image is required'})
							set_disablebutton(false)	
							return false
						}
						else{

							const docRef = await addDoc(collection(db, "portfolio"), {
								image: (uploadedFile) ? uploadedFile : data.image,
								name:data.name,                 
								industry_id: data.industry_id,                   
								service_id: data.service_id,     
								technologies: data.technologies.join(','), 
								short_description: data.short_description,  
								long_description: data.long_description, 							
								project_date: data.project_date, 
								project_url: data.project_url, 
								client_name: data.client_name, 
								location: data.location, 
								status: parseInt(data.status), 							        
								create_date: new Date(),  
								modified_date: new Date(),
							});	
	
							//=== insert id on table
							const docRef2 = doc(db, "portfolio", docRef.id);      
							await updateDoc(docRef2, {						  
								id: docRef.id,
							}); 
							//========
	
							setIsUpdated(true) 
							set_disablebutton(false)							
							MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
						}
						
					});	

			}
            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/portfolio' />			
	}	   

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/portfolio">Portfolio</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">
		
		<div className="form-group">
			<label className="form-label">
				Image 
			    <small style={{color:'#aaa',paddingLeft:"10px"}}>
					{`(Size : ${imageSize.width}X${imageSize.height} pixel)`}
				</small>
			</label>			
			{data.image &&                    
				<div>
				<input type='file' style={{display:"none"}} /> 
				<div className='imagebox'>     
				<img src={data.image} alt='' height={100} />
				<i className="bi bi-trash" onClick={ ()=>deleteFile(data.image,data.id) }></i>
				</div> 
				</div>
			}
			{!data.image &&
				<div>
					<input type='file' /> 
					{errors.image && 
						<div className="error-msg">{errors.image}</div>    
					} 						
					<div className="className mt-1" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
					<div className="progress-bar" style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
					</div>
				</div>
			}			
		</div>

		<div className="form-group">			
			<label className="form-label">Name</label>			
			<input type="text" className={`form-control ${errors.name ? 'error': ''} `} id="name" name="name" 
			value={data.name}
			onChange={handleChange} />
			{errors.name && 
				<div className="error-msg">{errors.name}</div>    
			} 			
		</div>

        <div className="row"> 
			<div className="col-6">
				<div className="form-group">
				<label className="form-label">Industry</label>			
				<select className={`form-select ${errors.industry_id ? 'error': ''} `} id="industry_id" name="industry_id" 
				value={data.industry_id}
				onChange={handleChange}>
				<option></option>
				{industries.map((item,i)=>{
					return <option key={i} value={item.id}>{item.name}</option>
				})}			
				</select>
				{errors.industry_id && 
					<div className="error-msg">{errors.industry_id}</div>    
				} 
				</div>
			</div>
			<div className="col-6">
				<div className="form-group">
				<label className="form-label">Service</label>			
				<select className={`form-select ${errors.service_id ? 'error': ''} `} id="service_id" name="service_id" 
				value={data.service_id}
				onChange={handleChange}>
				<option></option>
				{services.map((item,i)=>{
					return <option key={i} value={item.id}>{item.title}</option>
				})}			
				</select>
				{errors.service_id && 
					<div className="error-msg">{errors.service_id}</div>    
				} 
				</div>
			</div>
		</div>

		

		<div className="form-group">
			<label className="form-label">Technologies</label>	
			<div className="row">
			{technologiesData.map((item,i)=>{
               
				let checked = (data.technologies.includes(item.id) ) ? 'checked' : ''
				
				return (					
					<div  key={i} className="col-md-4">
					<div className="form-check">
					<input 
					className="form-check-input technlg" 
					type="checkbox" 
					name='technologies' 
					value={item.id} 
					onChange={handleChange} 
					checked={checked}
					/>
					<label className="form-check-label">{item.name}</label>
					</div>
					</div>					
				)				
				
			})}		
			</div>
			{errors.technologies && 
				<div className="error-msg">{errors.technologies}</div>    
			} 	
		</div>

		<div className="form-group">
			<label className="form-label">Short Description</label>			
			<textarea rows={5} className={`form-control ${errors.short_description ? 'error': ''} `} id="short_description" name="short_description" 
			value={data.short_description}
			onChange={handleChange} />
			{errors.short_description && 
				<div className="error-msg">{errors.short_description}</div>    
			} 
		</div>	

		<div className="form-group">
			<label className="form-label">Long Description</label>			
			<textarea rows={10} className={`form-control ${errors.long_description ? 'error': ''} `} id="long_description" name="long_description" 
			value={data.long_description}
			onChange={handleChange} />
			{errors.long_description && 
				<div className="error-msg">{errors.long_description}</div>    
			} 
		</div>

		<div className="row"> 
			<div className="col-6">
				<div className="form-group">			
				<label className="form-label">Project Date</label>			
				<input type="date" className={`form-control ${errors.project_date ? 'error': ''} `} id="project_date" name="project_date" 
				value={data.project_date}
				onChange={handleChange} />
				{errors.project_date && 
					<div className="error-msg">{errors.project_date}</div>    
				} 			
				</div>
			</div>
			<div className="col-6">
				<div className="form-group">
				<label className="form-label">Status</label>			
				<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
				value={data.status}
				onChange={handleChange}>
				<option></option>
				<option value={1}>Active</option>
				<option value={0}>InActive</option>
				</select>
				{errors.status && 
					<div className="error-msg">{errors.status}</div>    
				} 
				</div>
			</div>
        </div>

		<div className="form-group">			
			<label className="form-label">Project Url</label>			
			<input type="text" className={`form-control ${errors.project_url ? 'error': ''} `} id="project_url" name="project_url" 
			value={data.project_url}
			onChange={handleChange} />
			{errors.project_url && 
				<div className="error-msg">{errors.project_url}</div>    
			} 			
		</div>

		<div className="form-group">			
			<label className="form-label">Client Name</label>			
			<input type="text" className={`form-control ${errors.client_name ? 'error': ''} `} id="client_name" name="client_name" 
			value={data.client_name}
			onChange={handleChange} />
			{errors.client_name && 
				<div className="error-msg">{errors.client_name}</div>    
			} 			
		</div>

		<div className="form-group">			
			<label className="form-label">Location</label>			
			<input type="text" className={`form-control ${errors.location ? 'error': ''} `} id="location" name="location" 
			value={data.location}
			onChange={handleChange} />
			{errors.location && 
				<div className="error-msg">{errors.location}</div>    
			} 			
		</div>

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

