import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, logout } from "../../config/firebase-config";

// action ( from api )
export const fetchUser = createAsyncThunk("fetchUser", async ()=>{
    
    var objData = {};

    const promise = new Promise( async (resolve,reject)=>{
        const q = query(collection(db, "users"), where("uid", "==", localStorage.getItem('uid')));
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 
        resolve(result[0]) 
    }).then( async(response)=>{

        objData = response;

        const q2 = query(collection(db, "users_type"), where("user_typeID", "==", response.user_typeID));
        const querySnapshot2 = await getDocs(q2);	
        let result2 = querySnapshot2.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        })); 
        let rowData2 = result2[0];
        objData['user_type'] = rowData2.user_type
        return JSON.stringify(objData)         
    })  

    return promise     
    
})

// Slice
const slice = createSlice({
    name: 'user',
    initialState: {
        isLoading:false,
        data: null,
        isError:false,
        abcxyz:null
    },
    extraReducers:(builder)=>{
        builder.addCase(fetchUser.pending, (state,action)=>{
            state.isLoading = true
        })
        builder.addCase(fetchUser.fulfilled, (state,action) =>{
            state.isLoading = false
            state.data = action.payload
        })
        builder.addCase(fetchUser.rejected, (state,action)=>{
            console.log('error',action.payload)
            state.isError = true
        })
    },
    reducers: {
        loginSuccess: (state, action) => {
          state.abcxyz = action.payload;
        },
        logoutSuccess: (state, action) =>  {
          state.abcxyz = null;
        },
    },  
});
export default slice.reducer

// Actions ( normal )
export const { loginSuccess, logoutSuccess } = slice.actions