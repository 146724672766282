
import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar = (props)=>{	
   
	let ccp   	        = window.location.pathname;
	let ccp2     		= ccp.split('/')
	let current_path    = (ccp2[1]) ? ccp2[1] : ''

	const dashboard_menu 		= [''];  

	const page_child_1          = ['pages','add-page','edit-page'];  
	const page_child_2          = ['blocks','add-block','edit-block'];  
	const page_child_3          = ['banner-category','banners','add-banner','edit-banner'];  
	const page_menu       		= [
									...page_child_1,
									...page_child_2,
									...page_child_3,
								  ];  
	const testimonial_menu   	= ['testimonial','add-testimonial','edit-testimonial'];
	const service_menu    		= ['services','add-service','edit-service'];
	const emailtemplate_menu    = ['email-templates','add-email-template','edit-email-template'];
	const users_menu    		= ['users'];
	const settings_menu    		= ['settings','add-setting','edit-setting'];
	const faq_menu    			= ['faq','add-faq','edit-faq'];

	const portfolio_child_1     = ['industries','add-industry','edit-industry'];  
	const portfolio_child_2     = ['technologies','add-technology','edit-technology'];  
	const portfolio_child_3     = ['portfolio','add-portfolio','edit-portfolio'];  
	const portfolio_menu        = [
									...portfolio_child_1,
									...portfolio_child_2,
									...portfolio_child_3,
								  ];  
	const imagesize_menu    	= ['imagesize'];
	const contact_menu    		= ['contact'];	
	
	return (
		<>		    	   
		<aside id="sidebar" className="sidebar">
		<ul className="sidebar-nav" id="sidebar-nav">

			<li className="nav-item">
			<Link to="/" className={`nav-link ${dashboard_menu.includes(current_path) ? '' : 'collapsed'}`} >
			<i className="bi bi-grid"></i>
			<span>Dashboard</span>
			</Link>
			</li>

			<li className="nav-item">
			<Link to="/" className={`nav-link ${page_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#page-nave" data-bs-toggle="collapse">
			<i className="bx bxs-file"></i><span>Page</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="page-nave" className={`nav-content collapse ${page_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">
				<li>
				<Link to="/pages" className={`${page_child_1.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Page</span>
				</Link>
				</li>
				<li>
				<Link to="/blocks" className={`${page_child_2.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Block</span>
				</Link>
				</li>
				<li>
				<Link to="/banner-category" className={`${page_child_3.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Manage Banner</span>
				</Link>
				</li>				
				</ul>
			</li>

			<li className="nav-item">
			<Link to="/testimonial" className={`nav-link ${testimonial_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-aspect-ratio-fill"></i>
			<span>Testimonial</span>
			</Link>
			</li>


			<li className="nav-item">
			<Link to="/services" className={`nav-link ${service_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bx bxs-florist"></i>
			<span>Services</span>
			</Link>
			</li>
			

			<li className="nav-item">
			<Link to="/email-templates" className={`nav-link ${emailtemplate_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-envelope-fill"></i>
			<span>Email Template</span>
			</Link>
			</li>

			{/*
			<li className="nav-item">
			<Link to="/" className={`nav-link ${users_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#users-nav" data-bs-toggle="collapse">
			<i className="bx bxs-user"></i><span>Admin</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="users-nav" className={`nav-content collapse ${users_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/users" className={`${current_path==='users' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Admin Users</span>
				</Link>
				</li>	
				<li>
				<Link to="/user-type" className={`${current_path==='user-type' ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Admin User type</span>
				</Link>
				</li>			
				</ul>
			</li>
	        */}

			<li className="nav-item">
			<Link to="/faq" className={`nav-link ${faq_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-patch-question-fill"></i>
			<span>Faq</span>
			</Link>
			</li>


			<li className="nav-item">
			<Link to="/settings" className={`nav-link ${settings_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-gear-fill"></i>
			<span>Settings</span>
			</Link>
			</li>			

			<li className="nav-item">
			<Link to="/" className={`nav-link ${portfolio_menu.includes(current_path) ? '' : 'collapsed'}`} data-bs-target="#product-nav" data-bs-toggle="collapse">
			<i className="bi bi-bag-plus-fill"></i><span>Portfolio</span><i className="bi bi-chevron-down ms-auto"></i>
			</Link>
				<ul id="product-nav" className={`nav-content collapse ${portfolio_menu.includes(current_path) ? 'show' : ''}`} data-bs-parent="#sidebar-nav">				
				<li>
				<Link to="/industries" className={`${portfolio_child_1.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Industries</span>
				</Link>
				</li>	
				<li>
				<Link to="/technologies" className={`${portfolio_child_2.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Technologies</span>
				</Link>
				</li>	
				<li>
				<Link to="/portfolio" className={`${portfolio_child_3.includes(current_path) ? 'active' : ''}`}>
				<i className="bi bi-circle"></i><span>Portfolio</span>
				</Link>
				</li>			
				</ul>
			</li>

			{/* <li className="nav-item">
			<Link to="/imagesize" className={`nav-link ${imagesize_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-image"></i>
			<span>Image Size</span>
			</Link>
			</li> */}

			<li className="nav-item">
			<Link to="/contact" className={`nav-link ${contact_menu.includes(current_path) ? '' : 'collapsed'}`}>
			<i className="bi bi-telegram"></i>
			<span>Contact</span>
			</Link>
			</li>	

		</ul>
		</aside>
	    </>
	);
}
export default Sidebar;