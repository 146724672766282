
import Dashboard from '../components/pages/dashboard/Dashboard';
import Logout from '../components/pages/logout/Logout';
import My_profile from '../components/pages/my_profile/My_profile';

import Testimonial from '../components/pages/testimonial/List';
import TestimonialForm from '../components/pages/testimonial/Form';

import Setting from '../components/pages/settings/List';
import SettingForm from '../components/pages/settings/Form';

import Pages from '../components/pages/page/List';
import PageForm from '../components/pages/page/Form';

import Blocks from '../components/pages/block/List';
import BlockForm from '../components/pages/block/Form';

import BannerCatList from '../components/pages/banner/CatList';
import Banners from '../components/pages/banner/Banner';
import BannerForm from '../components/pages/banner/BannerForm';

import EmailTemplates from '../components/pages/email_templates/List';
import EmailTemplateForm from '../components/pages/email_templates/Form';

import Services from '../components/pages/services/List';
import ServiceForm from '../components/pages/services/Form';

import Faq from '../components/pages/faq/List';
import FaqForm from '../components/pages/faq/Form';

import Contact from '../components/pages/contact/List';

import Industries from '../components/pages/industries/List';
import IndustryForm from '../components/pages/industries/Form';

import Technologies from '../components/pages/technologies/List';
import TechnologyForm from '../components/pages/technologies/Form';

import Portfolio from '../components/pages/portfolio/List';
import PortfolioForm from '../components/pages/portfolio/Form';



const routes = [  
  {path: '/', exact: true, component: Dashboard },   
  {path: '/logout', exact: true, component: Logout },   
  {path: '/my-profile', exact: true, component: My_profile }, 

  {path: '/testimonial', exact: true, component: Testimonial },   
  {path: '/add-testimonial/', exact: true, component: TestimonialForm }, 
  {path: '/edit-testimonial/:id', exact: true, component: TestimonialForm },    

  {path: '/settings', exact: true, component: Setting },   
  {path: '/add-setting/', exact: true, component: SettingForm }, 
  {path: '/edit-setting/:id', exact: true, component: SettingForm },
  
  {path: '/pages', exact: true, component: Pages },   
  {path: '/add-page/', exact: true, component: PageForm }, 
  {path: '/edit-page/:id', exact: true, component: PageForm },

  {path: '/blocks', exact: true, component: Blocks },   
  {path: '/add-block/', exact: true, component: BlockForm }, 
  {path: '/edit-block/:id', exact: true, component: BlockForm },

  {path: '/banner-category', exact: true, component: BannerCatList },  
  {path: '/banners/:catid', exact: true, component: Banners },    
  {path: '/add-banner/:catid', exact: true, component: BannerForm }, 
  {path: '/edit-banner/:catid/:id', exact: true, component: BannerForm },

  {path: '/email-templates', exact: true, component: EmailTemplates },   
  {path: '/add-email-template/', exact: true, component: EmailTemplateForm }, 
  {path: '/edit-email-template/:id', exact: true, component: EmailTemplateForm },

  {path: '/services', exact: true, component: Services },   
  {path: '/add-service/', exact: true, component: ServiceForm }, 
  {path: '/edit-service/:id', exact: true, component: ServiceForm },

  {path: '/faq', exact: true, component: Faq },   
  {path: '/add-faq/', exact: true, component: FaqForm }, 
  {path: '/edit-faq/:id', exact: true, component: FaqForm },

  {path: '/contact', exact: true, component: Contact }, 
  
  {path: '/industries', exact: true, component: Industries },   
  {path: '/add-industry/', exact: true, component: IndustryForm }, 
  {path: '/edit-industry/:id', exact: true, component: IndustryForm },

  {path: '/technologies', exact: true, component: Technologies },   
  {path: '/add-technology/', exact: true, component: TechnologyForm }, 
  {path: '/edit-technology/:id', exact: true, component: TechnologyForm },

  {path: '/portfolio', exact: true, component: Portfolio },   
  {path: '/add-portfolio/', exact: true, component: PortfolioForm }, 
  {path: '/edit-portfolio/:id', exact: true, component: PortfolioForm },

];

export default routes;