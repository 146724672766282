import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { collection, doc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Testimonial' : 'Add Testimonial',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        profile_image: '',
		name: '',
        designation: '',		
		description: '',
        sort_order: 0,
        status: 1,		
	}
	const __errors = {
		profile_image: '',
		name: '',
        designation: '',		
		description: '',
        sort_order: '',
        status: '',		
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false);   
	const [disablebuttonclass, set_disablebuttonclass]   	= useState(""); 	
	const [errors,set_errors]     			   				= useState(__errors)     
    const [progresspercent, setProgresspercent]             = useState(0);
	const [isUpdated, setIsUpdated]                         = useState(false);
    const [imageSize, setImageSize]                         = useState({width:'',height:''});

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		getImageSize()
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "testimonial", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }
	const getImageSize = async ()=>{
        const docRef  = doc(db, "image_size", "83TMpuSoQlIeqfym1tMI");
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {			
			setImageSize({
				...docSnap.data(),
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const upload_file = (e)=>{	
        const promise = new Promise( (resolve,reject)=>{       
            const file = e.target[0]?.files[0]
            if(!file){
                resolve('') 
            } 
            const storageRef = ref(storage, `assets/upload/testimonial/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
                        resolve(downloadURL) 
                        setProgresspercent(0);
                    });
                }
            );
        })
        return promise        
	}

	const deleteFile = (fileUrl,id)=>{
        MySwal.fire({
            title: 'Are you sure?',  
            text: 'You want to delete selected item',                 
            showCancelButton: true,                 
            cancelButtonText: 'No',
            cancelButtonColor: '#3085d6',  
            confirmButtonText: 'Yes', 
            confirmButtonColor: '#d33'
        }).then((result) => {
            
            if(result.isConfirmed) {
                const desertRef = ref(storage, fileUrl);        
                deleteObject(desertRef).then( async () => {            
                    const docRef = doc(db, "testimonial", id);                
                    await updateDoc(docRef, {
                        profile_image: '',                
                    }); 
                    set_data({...data, ['profile_image']: ''})                    
                    MySwal.fire({icon: 'success', text:'item deleted succsessfully', confirmButtonColor: '#3085d6'})       

                })                  
            }
                       
        })
    }

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.name){
			isValid 			   = false;
			errors.name           = 'Name is required';
		}	
        if(!data.designation){
			isValid 			   = false;
			errors.designation     = 'Designation is required';
		}		    			
		if(!data.description){
			isValid 			   = false;
			errors.description     = 'Comment is required';
		}			
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){

            set_disablebutton(true)
            set_disablebuttonclass('btn-secondary disabled')

            const promise = upload_file(e)                    
            promise.then( async(uploadedFile)=>{                
                if(uploadedFile){
                    set_data({...data, ['profile_image']: uploadedFile})
                }   
				
				if(data.id){
					const docRef = doc(db, "testimonial", data.id);                
					await updateDoc(docRef, {
						profile_image: (uploadedFile) ? uploadedFile : data.profile_image,
						name: data.name,                   
						designation: data.designation,     
						description: data.description,     
						sort_order: parseInt(data.sort_order),     
						status: parseInt(data.status), 
						modified_date: new Date(),        
					}); 		
				}
				else{					
					const docRef = await addDoc(collection(db, "testimonial"), {
						profile_image: (uploadedFile) ? uploadedFile : data.profile_image,
						name: data.name,                   
						designation: data.designation,     
						description: data.description,     
						sort_order: parseInt(data.sort_order),     
						status: parseInt(data.status),   
						create_date: new Date(),  
						modified_date: new Date(),    
					});							
				}

				setIsUpdated(true) 
				set_disablebutton(false)
				set_disablebuttonclass('')                
				MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
            })
            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/testimonial' />			
	}		

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/testimonial">Testimonial</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">
		
		<div className="form-group">
			<label className="form-label">
				Profile Image 
				<small style={{color:'#aaa',paddingLeft:"10px"}}>
					{`(Size : ${imageSize.width}X${imageSize.height} pixel)`}
				</small>
			</label>			
			{data.profile_image &&                    
				<div>
				<input type='file' style={{display:"none"}} /> 
				<div className='imagebox'>     
				<img src={data.profile_image} alt='' height={100} />
				<i className="bi bi-trash" onClick={ ()=>deleteFile(data.profile_image,data.id) }></i>
				</div> 
				</div>
			}
			{!data.profile_image &&
				<div>
					<input type='file' /> 					
					<div className="className mt-1" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
					<div className="progress-bar" style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
					</div>
				</div>
			}			
		</div>

		<div className="form-group">			
			<label className="form-label">Name</label>			
			<input type="text" className={`form-control ${errors.name ? 'error': ''} `} id="name" name="name" 
			value={data.name}
			onChange={handleChange} />
			{errors.name && 
				<div className="error-msg">{errors.name}</div>    
			} 			
		</div>

		<div className="form-group">
			<label className="form-label">Designation</label>			
			<input type="text" className={`form-control ${errors.designation ? 'error': ''} `} id="designation" name="designation" 
			value={data.designation}
			onChange={handleChange} />
			{errors.designation && 
				<div className="error-msg">{errors.designation}</div>    
			} 
		</div>


		<div className="form-group">
			<label className="form-label">Comments</label>			
			<textarea rows={5} className={`form-control ${errors.description ? 'error': ''} `} id="description" name="description" 
			value={data.description}
			onChange={handleChange} />
			{errors.description && 
				<div className="error-msg">{errors.description}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="form-label">Sort order</label>			
			<input type="number" className={`form-control ${errors.sort_order ? 'error': ''} `} id="sort_order" name="sort_order" 
			value={data.sort_order}
			onChange={handleChange} />
			{errors.sort_order && 
				<div className="error-msg">{errors.sort_order}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary ${disablebuttonclass}`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

