import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { collection, doc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase-config";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Faq' : 'Add Faq',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {       
		question: '',
        answer: '',	
        sort_order: 0,
        status: 1,		
	}
	const __errors = {
		question: '',
        answer: '',	
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false);   	
	const [errors,set_errors]     			   				= useState(__errors) 
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "faq", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.question){
			isValid 			   = false;
			errors.question        = 'Question is required';
		}	
        if(!data.answer){
			isValid 			   = false;
			errors.answer          = 'Answer is required';
		}   
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){

            set_disablebutton(true)

            if(data.id){
				const docRef = doc(db, "faq", data.id);                
				await updateDoc(docRef, {					
					question: data.question,                   
					answer: data.answer, 
					sort_order: parseInt(data.sort_order),     
					status: parseInt(data.status), 
					modified_date: new Date(),        
				}); 		
			}
			else{					
				const docRef = await addDoc(collection(db, "faq"), {
					question: data.question,                   
					answer: data.answer, 
					sort_order: parseInt(data.sort_order),     
					status: parseInt(data.status), 					  
					create_date: new Date(), 
					modified_date: new Date(),     
					
				});							
			}

			setIsUpdated(true) 
			set_disablebutton(false)
			MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/faq' />			
	}	
	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/faq">Faq</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">		

		<div className="form-group">			
			<label className="form-label">Question</label>			
			<textarea rows={3} className={`form-control ${errors.question ? 'error': ''} `} id="question" name="question" 
			value={data.question}
			onChange={handleChange} />
			{errors.question && 
				<div className="error-msg">{errors.question}</div>    
			} 	
		</div>

		<div className="form-group">			
			<label className="form-label">Answer</label>			
			<textarea rows={10} className={`form-control ${errors.answer ? 'error': ''} `} id="answer" name="answer" 
			value={data.answer}
			onChange={handleChange} />
			{errors.answer && 
				<div className="error-msg">{errors.answer}</div>    
			} 	
		</div>

		<div className="form-group">
			<label className="form-label">Sort order</label>			
			<input type="number" className={`form-control ${errors.sort_order ? 'error': ''} `} id="sort_order" name="sort_order" 
			value={data.sort_order}
			onChange={handleChange} />
			{errors.sort_order && 
				<div className="error-msg">{errors.sort_order}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

