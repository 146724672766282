import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import { db } from "../../../config/firebase-config";
import { query, collection, getDocs, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const CatList = (props)=>{  
	
	const metaData = {
		meta_title			: 'Banner Category',
		meta_description	: '',
		meta_keywords		: '',
	} 

	const queryParams   = new URLSearchParams(window.location.search) 
    const item_per_page = 10
		
	const __filterData = {
        title: (queryParams.get('title')) ? (queryParams.get('title')) : '',		
	}

	const [filterData,set_filterData] 		= useState(__filterData) 	
	const [data,set_data]             		= useState([]) 
	const [total,set_total] 		        = useState(0)
	const [page, setPage]                   = useState(1);		

	useEffect(() => {
        fetchData()
	},[]); 

	const MySwal = withReactContent(Swal)

	const gueryFilter = ()=>{ 
		    
		let q = query(collection(db, "banner_category"));		
		if(filterData.title){
			q = query(q, where("title", ">=", filterData.title));	
			q = query(q, where("title", "<=", filterData.title));	
		}		
		return q	
	}

	const fetchData = async ()=>{ 
		let q = gueryFilter();		
		    q = query(q, orderBy('title','asc'), limit(item_per_page));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        }));   
		let q2 = gueryFilter();	
		const totalSnapshot = await getCountFromServer(q2);		
        const total         = totalSnapshot.data().count
		
        set_data(result)
		set_total(total)		
		setPage(1)
    }

	const showNext = async (item) => {		
        if(data.length === 0) {
        	alert("Thats all we have for now !")
        } 
		else{
			
			let q = gueryFilter();		
		    q = query(q, orderBy('title','asc'), limit(item_per_page), startAfter(item.title));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			}));  
			
            set_data(result);
            setPage(page + 1)
        }
    };

	const showPrevious = async (item) => {
		let q = gueryFilter();		
		q = query(q, orderBy('title','asc'), limitToLast(item_per_page), endBefore(item.title));	
		const querySnapshot = await getDocs(q);	
		let result = querySnapshot.docs.map((doc) => ({ 
			...doc.data(), 
			id: doc.id,             
		}));   

		set_data(result);
        setPage(page - 1)
    };	

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_filterData({...filterData, [field_name]: field_value})
	}	

	const handleFilter = (e)=>{
		e.preventDefault();	
		setBrowserUrl()	
		fetchData()	
	}

	const setBrowserUrl = ()=>{	
		const location = new URL(window.location);
		location.search="";
		if(filterData.title){
			location.searchParams.set('title', filterData.title);
		}		
		window.history.pushState({},'', location);
	}

	let total_page          = Math.ceil(total/item_per_page);  
	let start_text          = (total) ? ((page - 1) * item_per_page) + 1 : 0;
    let end_text            = (((page - 1) * item_per_page) > (total - item_per_page)) ? total : (((page - 1) * item_per_page) + item_per_page);
    let display_text        = `Showing ${start_text} to ${end_text}  of <strong>${total}</strong> results`;
	let sl_no               = (total) ? ((page - 1) * item_per_page) + 1 : 1;
		
    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>Banner Category</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>          
          <li className="breadcrumb-item active">Banner Category</li>
        </ol>
      </nav>
    </div>
	<section className="section">
		<div className="row">
			<div className="col-lg-12">
				<div className="card">
				
				<form id="frmFilter" name="frmFilter" method="post" encType="multipart/form-data" onSubmit={handleFilter}>				
				<div className="card-body table-responsive">					         
				<table className="table table-hover">
					<thead>
					<tr>
						<th scope="col" width="5%">SL</th>						
						<th scope="col">Title</th>
						<th scope="col">Width</th>
						<th scope="col">Height</th>
						<th scope="col" width="10%" className="text-center">Action</th>						
					</tr>
					<tr>
						<td>&nbsp;</td>									
						<td>
						<input className="form-control" type="text" 
						name="title" 
						value={filterData.title}  
						onChange={handleChange} />	
						</td>
						<td>&nbsp;</td>			
						<td>&nbsp;</td>	
						<td className="text-center">
						<button type="submit" name="OkFilter" id="OkFilter" className="btn  btn-primary">Filter</button>
						</td>
					</tr>
					</thead>
					<tbody>
					{data &&
						data.map((doc,index) => ( 
						<tr key={index+1}>							
							<td>{sl_no++}</td>							
							<td>{Parser(doc.title)}</td>
							<td>{doc.width}</td>
							<td>{doc.height}</td>							
							<td className="text-center">
							    
								<Link to={`/banners/${doc.id}`}>
								<button type="button" className="btn btn-info btn-sm" title='View Banners'>
								<i className="bi bi-eye-fill"></i>
								</button>
								</Link>
								
							</td>
						</tr> 						
                       ))}  									           
					</tbody>
				</table>				
				{data && data.length===0 &&						
					<div className='text-center'>No record Found!</div>						
  				} 			
				</div>
				</form>
				
				{total_page>1 && 
				<>
					<div className="card-body table-responsive">
						
					<div className="float-right">
					<span className="p-3">{Parser(display_text)}</span>
					<div className="btn-group">
                    
					{page === 1 ? 
						<button type="button" className="btn btn-secondary" disabled>Previous</button>
						: 
						<button type="button" className="btn btn-primary" onClick={() => showPrevious(data[0]) } >Previous</button>
					}

					{page < total_page ? 
						<button type="button" className="btn btn-primary" onClick={() => showNext(data[data.length - 1])}>Next</button>
						: 
						<button type="button" className="btn btn-secondary" disabled>Next</button>						
					}	

					</div>
					</div>
					</div>
					</>
				}				
                
			</div>    
			</div>
		</div>		
		</section>
	</main>
	</>
    );
 
}

export default CatList;

