
import React from 'react';

import Header from '../common/header/Header';
import Sidebar from '../common/sidebar/Sidebar';
import Footer from '../common/footer/Footer';

const Admin_layout = (props)=>{  

  return (  
    <>
    <Header />
    <Sidebar />
    <props.component />
    <Footer />
    </>
  );
 
}
export default Admin_layout;

