import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { query, collection, doc, getDoc, addDoc, updateDoc, where, getCountFromServer } from "firebase/firestore";
import { db, storage } from "../../../config/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';
import './services.css';


const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Service' : 'Add Service',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        image: '',	        	
		seo_url: '',
        title: '',
		short_description: '',		
        status: '',	
		sort_order:'',
		meta_title: '',  
		meta_keyword: '',  
		meta_description: '',  	
	}
	const __errors = {
		image: '',	        
		seo_url: '',		
        title: '',
		short_description: '',
        status: '',		
		sort_order:'',
		meta_title: '',  
		meta_keyword: '',  
		meta_description: '', 
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false);   	
	const [errors,set_errors]     			   				= useState(__errors)     
    const [progresspercent, setProgresspercent]             = useState(0);
	const [isUpdated, setIsUpdated]                         = useState(false);
    const [imageSize, setImageSize]                         = useState({width:'',height:''});
	const [sectionFields, setSectionFields]                 = useState([{section:''}]); 

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		getImageSize()
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "services", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = {
				...docSnap.data(),
				id:docSnap.id
			}			
			set_data(item)
			setSectionFields(item.section)   		
		} 		
    }
	const getImageSize = async ()=>{
        const docRef  = doc(db, "image_size", "rQJZECUhuYxIRaqPrNcG");
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {			
			setImageSize({
				...docSnap.data(),
				id:docSnap.id
			})			
		} 		
    }

	const addSectionField = ()=>{
        setSectionFields([...sectionFields,{section:''}])      
    }
	const removeSectionField = (index, evnt)=>{		
        const rows = [...sectionFields];
        rows.splice(index, 1);
        setSectionFields(rows);
    }

	const handleChangeSection = (index, evnt)=>{    
		const { name, value } = evnt.target;
		const list = [...sectionFields];
		list[index][name] = value;
		setSectionFields(list);
	}

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;

		if(field_name==='title'){
			let seo_url__ = field_value.trim().toLowerCase().replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-');
			set_data({
				...data, 
				['seo_url']: seo_url__,
				[field_name]: field_value
			})
		}
		else{
			set_data({...data, [field_name]: field_value})
		}
	}

	const upload_file = (e)=>{	
        const promise = new Promise( (resolve,reject)=>{       
            const file = e.target[0]?.files[0]
            if(!file){
                resolve('') 
            } 
            const storageRef = ref(storage, `assets/upload/services/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
                        resolve(downloadURL) 
                        setProgresspercent(0);
                    });
                }
            );
        })
        return promise        
	}

	const deleteFile = (fileUrl,id)=>{
        MySwal.fire({
            title: 'Are you sure?',  
            text: 'You want to delete selected item',                 
            showCancelButton: true,                 
            cancelButtonText: 'No',
            cancelButtonColor: '#3085d6',  
            confirmButtonText: 'Yes', 
            confirmButtonColor: '#d33'
        }).then((result) => {
            
            if(result.isConfirmed) {
                const desertRef = ref(storage, fileUrl);        
                deleteObject(desertRef).then( async () => {            
                    const docRef = doc(db, "services", id);                
                    await updateDoc(docRef, {
                        image: '',                
                    }); 
                    set_data({...data, ['image']: ''})                    
                    MySwal.fire({icon: 'success', text:'item deleted succsessfully', confirmButtonColor: '#3085d6'})       

                })                  
            }
                       
        })
    }

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.title){
			isValid 			   = false;
			errors.title           = 'Title is required';
		}	
        if(!data.short_description){
			isValid 			   		= false;
			errors.short_description    = 'Short description is required';
		}	
		if(!data.seo_url){
			isValid 			   = false;
			errors.seo_url         = 'Seo Url is required';
		}			    			
		if(data.status===''){
			isValid 			   = false;
			errors.status          = 'Status is required';
		}			
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){
				
			if(id){
				const promise = new Promise( async(resolve,reject)=>{       
					const q = query(collection(db, "services"), 
						where("seo_url", "==", (data.seo_url).toLowerCase()),
						where("id", "!=", id)
					);
					const querySnapshot = await getCountFromServer(q);						
					resolve(querySnapshot.data().count) 					
				})
				promise.then( async(count)=>{  
					if(count){
						set_errors({['seo_url']:'Duplicate Seo Url'})
						return false
					}
					else{

						set_disablebutton(true)

						const promise = upload_file(e)                    
						promise.then( async(uploadedFile)=>{  

							if(uploadedFile){
								set_data({...data, ['image']: uploadedFile})
							}  

							const docRef = doc(db, "services", id);                
							await updateDoc(docRef, {
								image: (uploadedFile) ? uploadedFile : data.image,								    
								title: data.title,                   
								short_description: data.short_description,   
								section: sectionFields,  
								meta_title: data.meta_title,  
								meta_keyword: data.meta_keyword,  
								meta_description: data.meta_description,  
								sort_order:parseInt(data.sort_order), 
								status: parseInt(data.status), 
								seo_url: (data.seo_url).toLowerCase(),  
								modified_date: new Date(),        
							}); 

							setIsUpdated(true) 
							set_disablebutton(false)							
							MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
						}); 			
					}
				})
				
			}
			else{

				const promise = new Promise( async(resolve,reject)=>{       
					const q = query(collection(db, "services"), 
					where("seo_url", "==", (data.seo_url).toLowerCase()),					
					);
					const querySnapshot = await getCountFromServer(q);						
					resolve(querySnapshot.data().count) 					
				})
				promise.then( async(count)=>{  
					if(count){
						set_errors({['seo_url']:'Duplicate Seo Url'})
						return false
					}
					else{

						set_disablebutton(true)

						const promise = upload_file(e)                    
						promise.then( async(uploadedFile)=>{                
							if(uploadedFile){
								set_data({...data, ['image']: uploadedFile})
							}  					
							const docRef = await addDoc(collection(db, "services"), {
							image: (uploadedFile) ? uploadedFile : data.image,							    
							title: data.title,                   
							short_description: data.short_description,   
							section: sectionFields,    
							meta_title: data.meta_title,  
							meta_keyword: data.meta_keyword,  
							meta_description: data.meta_description,  
							sort_order:parseInt(data.sort_order), 
							status: parseInt(data.status), 							
							seo_url: (data.seo_url).toLowerCase(),  
							create_date: new Date(),  
							modified_date: new Date(), 
							});	

							//=== insert id on table
							const docRef2 = doc(db, "services", docRef.id);      
							await updateDoc(docRef2, {						  
								id: docRef.id,
							}); 
							//========

							setIsUpdated(true) 
							set_disablebutton(false)							
							MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
						});	
					}
				})

			}
            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/services' />			
	}	


    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/services">Services</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">
		
		<div className="form-group">
			<label className="form-label">
				Image 
				<small style={{color:'#aaa',paddingLeft:"10px"}}>
					{`(Size : ${imageSize.width}X${imageSize.height} pixel)`}
				</small>
			</label>			
			{data.image &&                    
				<div>
				<input type='file' style={{display:"none"}} /> 
				<div className='imagebox'>     
				<img src={data.image} alt='' height={100} />
				<i className="bi bi-trash" onClick={ ()=>deleteFile(data.image,data.id) }></i>
				</div> 
				</div>
			}
			{!data.image &&
				<div>
					<input type='file' /> 					
					<div className="className mt-1" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
					<div className="progress-bar" style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
					</div>
				</div>
			}			
		</div>
		

		<div className="form-group">			
			<label className="form-label">Title</label>			
			<input type="text" className={`form-control ${errors.title ? 'error': ''} `} id="title" name="title" 
			value={data.title}
			onChange={handleChange} />
			{errors.title && 
				<div className="error-msg">{errors.title}</div>    
			} 			
		</div>

		<div className="form-group">			
			<label className="form-label">Seo Url</label>			
			<input type="text" className={`form-control ${errors.seo_url ? 'error': ''} `} id="seo_url" name="seo_url" 
			value={data.seo_url}
			onChange={handleChange} />
			{errors.seo_url && 
				<div className="error-msg">{errors.seo_url}</div>    
			} 			
		</div>

		<div className="form-group">
			<label className="form-label">Short Description</label>	 		
			<textarea rows={10} className={`form-control ${errors.short_description ? 'error': ''} `} id="short_description" name="short_description" 
			value={data.short_description}
			onChange={handleChange} />
			{errors.short_description && 
				<div className="error-msg">{errors.short_description}</div>    
			} 
		</div>	

		<div className="form-group">
			<label className="form-label">Content Section</label>
			{sectionFields.map((item, index)=>{					
					return(
						<div key={index} className="row mb-3">						
						<div className="col-12">
						<div className="form-group section-box">			
						<textarea rows={15} className="form-control" 
						name="section" 
						value={item.section} 
						onChange={(evnt)=>handleChangeSection(index, evnt)} />	

						{ (sectionFields.length > 1 ) ? <span className="remove" onClick={(evnt)=>removeSectionField(index, evnt)} >X</span> :'' }

						</div>
						</div>
						</div>
					)
				})
			}
			<div className="row mt-0">
				<div className="col-sm-12">
				<a className="btn btn-secondary btn-sm" onClick={addSectionField}>Add New Section</a>
				</div>
            </div>
		</div>		

		<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option></option>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="form-label">Sort order</label>			
			<input type="number" className={`form-control ${errors.sort_order ? 'error': ''} `} id="sort_order" name="sort_order" 
			value={data.sort_order}
			onChange={handleChange} />
			{errors.sort_order && 
				<div className="error-msg">{errors.sort_order}</div>    
			} 
		</div>

		<div className="form-group">			
			<label className="form-label">Meta title</label>			
			<textarea rows={5} className={`form-control ${errors.meta_title ? 'error': ''} `} id="meta_title" name="meta_title" 
			value={data.meta_title}
			onChange={handleChange} />
			{errors.meta_title && 
				<div className="error-msg">{errors.meta_title}</div>    
			} 
		</div>
		<div className="form-group">			
			<label className="form-label">Meta keyword</label>			
			<textarea rows={5} className={`form-control ${errors.meta_keyword ? 'error': ''} `} id="meta_keyword" name="meta_keyword" 
			value={data.meta_keyword}
			onChange={handleChange} />
			{errors.meta_keyword && 
				<div className="error-msg">{errors.meta_keyword}</div>    
			} 
		</div>
		<div className="form-group">			
			<label className="form-label">Meta descriptiont</label>			
			<textarea rows={5} className={`form-control ${errors.meta_description ? 'error': ''} `} id="meta_description" name="meta_description" 
			value={data.meta_description}
			onChange={handleChange} />
			{errors.meta_description && 
				<div className="error-msg">{errors.meta_description}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

