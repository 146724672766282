import React, { useState, useEffect, Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation  } from 'react-router-dom';

import public_routes from './routes/public_routes';
import private_routes from './routes/private_routes';
import Admin_layout from './components/layouts/Admin_layout';

import './App.css';

const IsLogin = ({ children })=>{
  const location = useLocation();
  if( localStorage.getItem('uid') ){
    return children
  }
  else{
    return <Navigate to="/login" replace state={{ path: location.pathname }} />
  }  
}

function App(props) {
  return (
    <Router> 
        <Routes>
        
        {public_routes.map( (item,i) =>{           
            return <Route 
            key={i} 
            path={item.path} 
            element={<item.component />}
            />           
          })}  
        
          {private_routes.map( (item,i) =>{           
            return <Route 
            key={i} 
            path={item.path} 
            element={
            <IsLogin>   
            <Admin_layout component={item.component} />
            </IsLogin>
            }
            />           
          })}  

        </Routes>
    </Router>
    
  );
}

export default App;
