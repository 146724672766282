import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { query, collection, doc, getDoc, getDocs, addDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../../config/firebase-config";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';
import { ElseIf } from 'react-if-elseif-else-render';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Setting' : 'Add Setting',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        title: '',
		option: '',
        value: '',	
        type: '',        
	}
	const __errors = {
		title: '',
		option: '',
        //value: '',		
		type: '',       	
	}

	const [data,set_data]  									= useState(__data)     	
	const [errors,set_errors]     			   				= useState(__errors)  
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "settings", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.title){
			isValid 			   = false;
			errors.title           = 'Title is required';
		}	
        if(!data.option){
			isValid 			   = false;
			errors.option          = 'Option is required';
		}		    			
		// if(!data.value){
		// 	isValid 			   = false;
		// 	errors.value           = 'Value is required';
		// }
		if(data.type===''){
			isValid 			   = false;
			errors.type            = 'Type is required';
		}				
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){

            if(data.id){

				const docRef = doc(db, "settings", data.id);                
				await updateDoc(docRef, {
					value: data.value, 
				}); 
				
				setIsUpdated(true) 				            
				MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 

			}
			else{	
				
				const promise = new Promise( async(resolve,reject)=>{       
					const q = query(collection(db, "settings"), where("option", "==", data.option));
					const querySnapshot = await getDocs(q);	
					let result = querySnapshot.docs.map((doc) => ({ 
						...doc.data(), 
						id: doc.id,             
					}));  
					resolve(result.length) 
				})
				promise.then( async(count)=>{
					if(count){
						set_errors({['option']:'Duplicate Option'})
						return false
					}
					else{
						const docRef = await addDoc(collection(db, "settings"), {
							title: data.title,                   
							option: data.option, 
							value: data.value, 
							type: parseInt(data.type),     
							deletable: 1, 		    
						});	

						setIsUpdated(true) 				            
				        MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 	
					}
				})
								
			}
            
		}			
	}

	const radioHtml = (data,errors)=>{	
		let checked_1 = 0
		let checked_2 = 0
		if( parseInt(data.value) === 1 ){
			checked_1 = 1
			checked_2 = 0
		}
		else if( parseInt(data.value) === 0){
			checked_1 = 0
			checked_2 = 1
		}
		return(
			<div className="form-group">
				<label className="form-label">{data.title}</label>	

				<div className="form-check">
				<input className="form-check-input" type="radio" name="value" id="flexRadioDefault1" value={1} checked={checked_1} onChange={handleChange} />
				<label className="form-check-label" for="flexRadioDefault1">
				Yes
				</label>
				</div>
				<div className="form-check">
				<input className="form-check-input" type="radio" name="value" id="flexRadioDefault2" value={0} checked={checked_2} onChange={handleChange} />
				<label className="form-check-label" for="flexRadioDefault2">
				No
				</label>
				</div>					

				{errors.value && 
					<div className="error-msg">{errors.value}</div>    
				} 
			</div>
		)
	}
    
	if(isUpdated){			
		return <Navigate  to='/settings' />			
	}	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/settings">Settings</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
		

	   {id ?           
			<div className="col-12 pt-3">				

				{data.type==0 && 
					<div className="form-group">
					<label className="form-label">{data.title}</label>			
					<input type="text" className={`form-control ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={data.value}
					onChange={handleChange} />
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}
				{data.type==1 && 
					<div className="form-group">
					<label className="form-label">{data.title}</label>			
					<textarea className={`form-control ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={data.value}
					rows={5}
					onChange={handleChange} />
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}
				{data.type==2 && 				    
					radioHtml(data,errors)
				}
				{data.type==3 && 
					<div className="form-group">
					<label className="form-label">{data.title}</label>			
					<select className={`form-select ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={parseInt(data.value)}
					onChange={handleChange} >
					<option></option>					
					<option value={1}>Yes</option>
					<option value={0}>No</option>					
					</select>
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}


				<div className="form-group">
				<label className="form-label"></label>				
				<button 
				type="submit"                
				className={`btn btn-primary`}				
				>Save Changes</button>				
				</div>

			</div>

			:
			<div className="col-12 pt-3">

				<div className="form-group">			
				<label className="form-label">Title</label>			
				<input type="text" className={`form-control ${errors.title ? 'error': ''} `} id="title" name="title" 
				value={data.title}
				onChange={handleChange} />
				{errors.title && 
					<div className="error-msg">{errors.title}</div>    
				} 			
				</div>

				<div className="form-group">
				<label className="form-label">Option</label>			
				<input type="text" className={`form-control ${errors.option ? 'error': ''} `} id="option" name="option" 
				value={data.option}
				onChange={handleChange} />
				{errors.option && 
					<div className="error-msg">{errors.option}</div>    
				} 
				</div>

				<div className="form-group">
				<label className="form-label">Type</label>			
				<select className={`form-select ${errors.type ? 'error': ''} `} id="type" name="type" 
				value={data.type}
				onChange={handleChange} >
				<option></option>
				<option value={0}>Text</option>
				<option value={1}>TextArea</option>
				<option value={2}>RadioButton</option>
				<option value={3}>DropDown</option>
				</select>
				{errors.type && 
					<div className="error-msg">{errors.type}</div>    
				} 
				</div>

				{data.type==0 && 
					<div className="form-group">
					<label className="form-label">Value</label>			
					<input type="text" className={`form-control ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={data.value}
					onChange={handleChange} />
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}
				{data.type==1 && 
					<div className="form-group">
					<label className="form-label">Value</label>			
					<textarea className={`form-control ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={data.value}
					rows={5}
					onChange={handleChange} />
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}
				{data.type==2 && 
					<div className="form-group">
					<label className="form-label">Value</label>	

					<div className="form-check">
					<input className="form-check-input" type="radio" name="value" id="flexRadioDefault1" value={1} onChange={handleChange} />
					<label className="form-check-label" for="flexRadioDefault1">
					Yes
					</label>
					</div>
					<div className="form-check">
					<input className="form-check-input" type="radio" name="value" id="flexRadioDefault2" value={0} onChange={handleChange} />
					<label className="form-check-label" for="flexRadioDefault2">
					No
					</label>
					</div>					

					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}
				{data.type==3 && 
					<div className="form-group">
					<label className="form-label">Value</label>			
					<select className={`form-select ${errors.value ? 'error': ''} `} id="value" name="value" 
					value={data.value}
					onChange={handleChange} >
					<option></option>					
					<option value={1}>Yes</option>
					<option value={0}>No</option>					
					</select>
					{errors.value && 
						<div className="error-msg">{errors.value}</div>    
					} 
					</div>
				}


				<div className="form-group">
				<label className="form-label"></label>				
				<button 
				type="submit"                
				className={`btn btn-primary`}				
				>Save Changes</button>				
				</div>

			</div>
	   }		


	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

