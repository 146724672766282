import React, { useState, useEffect } from 'react';
import Parser from 'html-react-parser';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { collection, doc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase-config";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';



const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Email Template' : 'Add Email Template',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        title: '',
		subject: '',
        body: '',
        status: '',		
	}
	const __errors = {
		title: '',
		subject: '',
        body: '',	
        status: '',		
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false);   	
	const [errors,set_errors]     			   				= useState(__errors) 
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "email_template", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }	

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.title){
			isValid 			   = false;
			errors.title           = 'Title is required';
		}	
        if(!data.subject){
			isValid 			   = false;
			errors.subject         = 'Subject is required';
		}	
		if(!data.body){
			isValid 			   = false;
			errors.body            = 'Body is required';
		}		    			
		if(data.status===''){
			isValid 			   = false;
			errors.status          = 'Status is required';
		}			
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){
				
			if(id){
				
				set_disablebutton(true)

				const docRef = doc(db, "email_template", id);                
				await updateDoc(docRef, {
					title: data.title,                   
					subject: data.subject,     
					body: data.body, 
					status: parseInt(data.status), 
					modified_date: new Date(),        
				}); 

				setIsUpdated(true) 
				set_disablebutton(false)							
				MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
				
			}
			else{

				const docRef = await addDoc(collection(db, "email_template"), {
					title: data.title,                   
					subject: data.subject,     
					body: data.body, 
					status: parseInt(data.status), 					
					create_date: new Date(),  
					modified_date: new Date(),  
					deletable:1      				
				});					

				setIsUpdated(true) 
				set_disablebutton(false)							
				MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 

			}
            
		}			
	}

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}	

	const getParameters = (object)=>{
		var html = ''
		    html += '<ul>'
		for (const [key, value] of Object.entries(object)) {
			html += '<li>'
			html += `{${key}} : ${value}`
			html += '</li>'
		}
		html += '</ul>'
		return html 
	}
    
	if(isUpdated){			
		return <Navigate  to='/email-templates' />			
	}	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/email-templates">Email Template</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">

		{!id || data.deletable===1 ?

			<div className="form-group">			
			<label className="form-label">Title</label>			
			<input type="text" className={`form-control ${errors.title ? 'error': ''} `} id="title" name="title" 
			value={data.title}
			onChange={handleChange} />
			{errors.title && 
				<div className="error-msg">{errors.title}</div>    
			} 			
			</div>						
			:
			<div className="form-group">
			<h5>{data.title}</h5>	
			</div>			
		}		

		<div className="form-group">			
			<label className="form-label">Subject</label>			
			<input type="text" className={`form-control ${errors.subject ? 'error': ''} `} id="subject" name="subject" 
			value={data.subject}
			onChange={handleChange} />
			{errors.subject && 
				<div className="error-msg">{errors.subject}</div>    
			} 			
		</div>	

		{id && data.parameters &&			
			<div className="form-group">
			<label className="form-label">
			Parameters
			</label>
			<p>Please use the below parameters in email template Body</p>			
			{Parser(getParameters(data.parameters))}
			</div>			
		}	

		<div className="form-group">
			<label className="form-label">Body</label>			
			<textarea rows={15} className={`form-control ${errors.body ? 'error': ''} `} id="body" name="body" 
			value={data.body}
			onChange={handleChange} />
			{errors.body && 
				<div className="error-msg">{errors.body}</div>    
			} 
		</div>		

		<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option></option>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
		</div>
		

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

