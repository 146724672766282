import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { fetchUser } from '../../../../redux/slice/user'

let Overview = (props)=>{

    const dispatch      = useDispatch()
	const  adminState   = useSelector( (state)=> state.user )  
    const  admin        = (adminState.data) ? JSON.parse(adminState.data) : null;
     	
	useEffect(() => {
        dispatch(fetchUser())        
	},[]);

    if(!admin){
        return 'loading...'
    }   

    return(
        <>
            <h5 className="card-title">About</h5>
            <p className="small fst-italic">{admin.about}</p>

            <h5 className="card-title">Profile Details</h5>

            <div className="row">
            <div className="col-lg-3 col-md-4 label ">Full Name</div>
            <div className="col-lg-9 col-md-8">{admin.fname} {admin.lname}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Job</div>
            <div className="col-lg-9 col-md-8">{admin.user_type}</div>
            </div>					

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Address</div>
            <div className="col-lg-9 col-md-8">{admin.address}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Phone</div>
            <div className="col-lg-9 col-md-8">{admin.phone}</div>
            </div>

            <div className="row">
            <div className="col-lg-3 col-md-4 label">Email</div>
            <div className="col-lg-9 col-md-8">{admin.email}</div>
            </div>
            
        </>
    )
}
export default Overview;