import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { collection, doc, getDoc, addDoc, updateDoc} from "firebase/firestore";
import { db } from "../../../config/firebase-config";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Technology' : 'Add Technology',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
		name: '',       
	}
	const __errors = {
		name: '',
	}

	const [data,set_data]  									= useState(__data) 
    const [disablebutton, set_disablebutton] 				= useState(false); 
	const [errors,set_errors]     			   				= useState(__errors) 
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "technologies", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.name){
			isValid 			   = false;
			errors.name           = 'Name is required';
		}
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){

            set_disablebutton(true)

            if(data.id){
				const docRef = doc(db, "technologies", data.id);                
				await updateDoc(docRef, {
					name: data.name, 
					modified_date: new Date(),        
				}); 		
			}
			else{					
				const docRef = await addDoc(collection(db, "technologies"), {
					name: data.name, 
					create_date: new Date(),  
					modified_date: new Date(),    
				});							
			}

			setIsUpdated(true) 
			set_disablebutton(false)			      
			MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/technologies' />			
	}	
	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/technologies">Technologies</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">		

		<div className="form-group">			
			<label className="form-label">Name</label>			
			<input type="text" className={`form-control ${errors.name ? 'error': ''} `} id="name" name="name" 
			value={data.name}
			onChange={handleChange} />
			{errors.name && 
				<div className="error-msg">{errors.name}</div>    
			} 			
		</div>


		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

