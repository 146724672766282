import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser,loginSuccess,logoutSuccess } from '../../../../redux/slice/user'

let ProfileCard = (props)=>{

    const dispatch     = useDispatch()
	const adminState   = useSelector( (state)=> state.user )  
    const admin        = (adminState.data) ? JSON.parse(adminState.data) : null;
     	
	useEffect(() => {
        dispatch(fetchUser())
        dispatch(loginSuccess(['ggg','gg','ffffff']))
        dispatch(logoutSuccess(null))
	},[]);   

    if(!admin){
        return 'loading...'
    }   

    const fireBaseTime = new Date(
        admin.create_date.seconds * 1000 + admin.create_date.nanoseconds / 1000000
    );

    return(
        <>
        <div className="card">
        <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
            
            {admin && admin.profile_image ? ( 
			<img src={admin.profile_image} className="rounded-circle" alt="" />
            ) : (
                <img src="/assets/img/profile-img.png" className="rounded-circle" alt="" />
            )}

            <h2>{admin.fname} {admin.lname}</h2>
            <h3>{admin.user_type}</h3>  
            <p>Member since : {dayjs(fireBaseTime).format('D MMM, YYYY h:mm A')}</p>  
                    
            {admin.social_media &&
                <div className="social-links mt-2">
                    {/* {social_media.twitter &&
                        <a href={social_media.twitter} target="_blank" rel="noreferrer" className="twitter"><i className="bi bi-twitter"></i></a>
                    }
                    {social_media.facebook &&
                        <a href={social_media.facebook} target="_blank" rel="noreferrer" className="facebook"><i className="bi bi-facebook"></i></a>
                    }
                    {social_media.instagram &&
                        <a href={social_media.instagram} target="_blank" rel="noreferrer" className="instagram"><i className="bi bi-instagram"></i></a>
                    }
                    {social_media.linkedin &&
                        <a href={social_media.linkedin} target="_blank" rel="noreferrer" className="linkedin"><i className="bi bi-linkedin"></i></a>
                    } */}
                </div>
            }
        </div>
        </div>
        </>
    )
}
export default ProfileCard;