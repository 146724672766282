import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Navigate, Link } from 'react-router-dom';

import { signInWithEmailAndPassword } from "firebase/auth";
import { collection, query, where, getDocs  } from "firebase/firestore";
import { auth, db, storage, ref, getDownloadURL } from '../../../config/firebase-config';

import Meta from '../../common/Meta';
import validation from '../../../config/validation';

const Login = (props)=>{ 

    const metaData = {
      meta_title			: 'Login',
      meta_description	: '',
      meta_keywords		: '',
    } 

    const __data = {		
      email: '',
      password: ''
    }
    const __errors = {	
      email: '',
      password: ''
    }

    const [data,set_data]   	  						            = useState(__data) 
    const [disablebutton, set_disablebutton] 			      = useState(false);   
    const [disablebuttonclass, set_disablebuttonclass]  = useState("");   
    const [checked,set_checked]   						          = useState(false)  
    const [loggedIn,set_loggedIn] 						          = useState(false)  
    const [common_error,set_common_error] 				      = useState("")  
    const [errors,set_errors]     						          = useState(__errors) 
    const [logo,set_logo] 								              = useState("")  

    useEffect(()=>{
      get_firebase_assets_url()
    },[])    

    useEffect( ()=>{
      if(localStorage.getItem('remember_me')){			
        set_checked(true);
        set_data({
          ...data, 
          email:localStorage.getItem('email') ? localStorage.getItem('email') :'',
          password:localStorage.getItem('password') ? localStorage.getItem('password') :'',
        })						
      } 
    },[])
  
    if(loggedIn){			
      return <Navigate  to='/' />			
    }	

    const get_firebase_assets_url = ()=>{
      const logoRef = ref(storage, 'assets/upload/logo/logo.png');    
      getDownloadURL(logoRef).then( (url)=>{
        set_logo(url)
      })
    }	

    const handleChange = (e)=>{	
      const field_name  = e.target.name;
      const field_value = e.target.value;
      set_data({...data, [field_name]: field_value})
    }	
  
    const handleRememberMe =(e)=>{		
      if(checked){
        set_checked(false);
      }
      else{
        set_checked(true);
      }		
    }

    const validateForm = ()=>{		
      let errors          = {};  
      let isValid         = true;   
      
      if(!validation.validateEmail(data.email)){
        isValid 		       = false;
        errors.email       = 'Email is not valid!';
      }			
      if(!data.password){
        isValid 			   = false;
        errors.password  = 'Password is required';
      }	
      set_errors(errors);	
      return isValid;		 
    }

    const handleSubmit = async(e)=>{
      e.preventDefault();   
      if(validateForm()){	
        set_disablebutton(true)
        set_disablebuttonclass('btn-secondary disabled')
              
        await signInWithEmailAndPassword(auth, data.email, data.password)
        .then( async (userCredential) => {	
          const q = query(collection(db, "users"), where("email", "==", data.email));
          const querySnapshot = await getDocs(q);	
          querySnapshot.forEach( async (docItem) => {				
            const docId  = docItem.id
            const docRow = docItem.data()		

            if(checked){
              localStorage.setItem('remember_me','1');
              localStorage.setItem('email',data.email);
              localStorage.setItem('password',data.password);
            }
            else{
              localStorage.removeItem('remember_me');
              localStorage.removeItem('email');
              localStorage.removeItem('password');		
            }		
            localStorage.setItem('uid',userCredential.user.uid);	
            set_loggedIn(true)

          });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if( errorCode==='auth/user-not-found' ){
            set_errors({email:'Wrong Email'})
            set_disablebutton(false)
            set_disablebuttonclass('')
          }	
          if( errorCode==='auth/wrong-password' ){
            set_errors({password:'Wrong password'})
            set_disablebutton(false)
            set_disablebuttonclass('')
          }
        });
      }			
    }	

    return (  
      <>  

      <Meta metaData={metaData} />
      <HelmetProvider>
      <Helmet>		
      </Helmet>				
      </HelmetProvider>	

      <main>
      <div className="container">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div className="container">
      <div className="row justify-content-center">
      <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
      <div className="d-flex justify-content-center py-4">
      <div className="logo d-flex align-items-center w-auto">
      <img src={logo} alt="" />			
      </div> 
      </div>
      <div className="card mb-3">
      <div className="card-body">
        <div className="pt-4 pb-2">
        <h5 className="card-title text-center pb-0 fs-4">Login</h5>
        <p className="text-center small">Sign in to start your session</p>
        </div>
        {common_error &&            
            <div className="alert alert-danger alert-dismissible fade show">
            {common_error}            
            </div> 
        }          
        <form className="row g-3 needs-validation" method="post" onSubmit={handleSubmit}>
        
        <div className="col-12">
        <div className={`input-group ${errors.email ? 'error' : ''}`}>
        <input 
        type="text" 
        className="form-control" 
        placeholder="Email" 
        id="email"
        name="email" 
        value={data.email} 
        onChange={handleChange}
        autoComplete="off"/>				  
        </div>
        {errors.email && 
        <div className="error-msg">{errors.email}</div>    
        }  				
        </div>

        <div className="col-12">				
        <div className={`input-group ${errors.password ? 'error' : ''}`}> 
        <input 
        type="password" 
        className="form-control" 
        placeholder="Password" 
        id="password"
        name="password" 
        value={data.password} 
        onChange={handleChange}
        autoComplete="off"/>				  
        </div>
        {errors.password &&
        <div className="error-msg">{errors.password}</div>    
        }  
        </div>

        <div className="col-12">
        <div className="form-check">				
        <input 
        className="form-check-input"
        type="checkbox" 
        name="rememberMe" 
        id="rememberMe"
        value="1" 
        checked={checked} 
        onChange={handleRememberMe} />
        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
        </div>
        </div>

        <div className="col-12">
        <button 			
        type="submit"
        className={`btn btn-primary w-100 ${disablebuttonclass}`}
        disabled={disablebutton}
        >Login</button>			
        </div>

        <div className="col-12">
        <p className="small mb-0"> 
        <Link to="/forgot-password">I forgot my password</Link>         
        </p>			
        </div>

        </form>

      </div>
      </div>		
      </div>
      </div>
      </div>
      </section>
      </div>
      </main>

       </>
    
    );
 
}
export default Login;

