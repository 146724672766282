import React, {useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import { useSelector, useDispatch } from 'react-redux'
import { fetchUser,loginSuccess,logoutSuccess } from '../../../../redux/slice/user'

const Profile_top = ()=>{

    const dispatch     = useDispatch()
	const adminState   = useSelector( (state)=> state.user )  
    const admin        = (adminState.data) ? JSON.parse(adminState.data) : null;

	useEffect(() => {
        dispatch(fetchUser())        
	},[]); 

	if(!admin){
        return false
    } 
	
	const fireBaseTime = new Date(
        admin.create_date.seconds * 1000 + admin.create_date.nanoseconds / 1000000
    );

    return(         
		<>
        <Link className="nav-link nav-profile d-flex align-items-center pe-0" to="#" data-bs-toggle="dropdown">		
		{admin.profile_image ? ( 
			<img src={admin.profile_image} className="rounded-circle profile-img" alt="" />
		) : (
			<img src="/assets/img/profile-img.png" className="img-circle profile-img rounded-circle" alt="" />
		)}
		<span className="d-none d-md-block dropdown-toggle ps-2 profile-name">{admin.fname}</span>
		</Link>

		<ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
		<li className="dropdown-header">
		<h6 className="profile-name">{admin.fname}</h6>
		<span>{admin.user_type}</span>		
		</li>
		<li>
		<hr className="dropdown-divider" />
		</li>

		<li>
		<Link className="dropdown-item d-flex align-items-center" to="/my-profile">
		<i className="bi bi-person"></i>
		<span>My Profile</span>
		</Link>
		</li>
		<li>
		<hr className="dropdown-divider" />
		</li>		
		<li>
		<hr className="dropdown-divider" />
		</li>	

		<li>
		<Link className="dropdown-item d-flex align-items-center" to="/logout">
		<i className="bi bi-box-arrow-right"></i>
		<span>Sign Out</span>
		</Link>
		</li>
		</ul>
        </>
    )
}
export default Profile_top;