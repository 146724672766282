import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { signOut } from "firebase/auth";
import { auth, db } from '../../../config/firebase-config';

const Logout = (props)=> {				
	
	localStorage.removeItem('uid');	

	// Note : useEffect must be write on top, before any return statement
	useEffect( ()=>{
		signOutUser()
	},[])

	const signOutUser = async ()=>{
		await signOut(auth).then(() => {			
		console.log('Sign-out successful')
		}).catch((error) => {			
		console.log(error)
		});
	}
  
	if(!localStorage.getItem('uid')){	
		return <Navigate  to='/login' />		
	}  
}
export default Logout;