import React from 'react';
import './Reset_password.css';

const Reset_password = (props)=>{     
		
    return ( 
	<>		
	Reset password	
	</>
    );
 
}

export default Reset_password;

