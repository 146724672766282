import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { query, collection, doc, getDoc, getDocs, addDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../../config/firebase-config";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const Form = (props)=>{ 	

	const params = useParams();	
    const id     = (params.id) ? params.id : ''
	const metaData = {
		meta_title			: (id) ? 'Edit Block' : 'Add Block',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        title: '',
		identity: '',
        content: '',
        status: '',		
	}
	const __errors = {
		title: '',
		identity: '',
        content: '',
        status: '',		
	}

	const [data,set_data]  									= useState(__data)     
	const [errors,set_errors]     			   				= useState(__errors) 
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		}        
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "blocks", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!data.title){
			isValid 			   = false;
			errors.title           = 'Title is required';
		}	
        if(!data.identity){
			isValid 			   = false;
			errors.identity        = 'Identity is required';
		}		    			
		if(!data.content){
			isValid 			   = false;
			errors.content         = 'Content is required';
		}	
		if(data.status===''){
			isValid 			   = false;
			errors.status          = 'Status is required';
		}				
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){

            if(data.id){
				const docRef = doc(db, "blocks", data.id);                
				await updateDoc(docRef, {					
					title: data.title, 
					content: data.content, 
					status: parseInt(data.status), 
					modified_date: new Date(),        
				}); 
				
				setIsUpdated(true) 				            
				MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
			}
			else{	
				
				const promise = new Promise( async(resolve,reject)=>{       
					const q = query(collection(db, "blocks"), where("identity", "==", (data.identity).toLowerCase()));
					const querySnapshot = await getDocs(q);	
					let result = querySnapshot.docs.map((doc) => ({ 
						...doc.data(), 
						id: doc.id,             
					}));  
					resolve(result.length) 
				})
				promise.then( async(count)=>{
					if(count){
						set_errors({['identity']:'Duplicate identity'})
						return false
					}
					else{
						const docRef = await addDoc(collection(db, "blocks"), {
							title: data.title,                   
							identity: (data.identity).toLowerCase(),     
							content: data.content,  
							status: parseInt(data.status),   
							create_date: new Date(),  
							modified_date: new Date(),  
							deletable:1,       
						});			

						setIsUpdated(true) 				            
				        MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 	
					}
				})
								
			}            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to='/blocks' />			
	}	
	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/blocks">Blocks</Link></li>        
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	
		
    {id ?
		<div className="col-12 pt-3">	

			<div className="form-group">			
			<label className="form-label">Title</label>			
			<input type="text" className={`form-control ${errors.title ? 'error': ''} `} id="title" name="title" 
			value={data.title}
			onChange={handleChange} />
			{errors.title && 
				<div className="error-msg">{errors.title}</div>    
			} 			
			</div>

			<div className="form-group">
			<label className="form-label">Content</label>			
			<textarea rows={15} className={`form-control ${errors.content ? 'error': ''} `} id="content" name="content" 
			value={data.content}
			onChange={handleChange} />
			{errors.content && 
				<div className="error-msg">{errors.content}</div>    
			} 
			</div>

			<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
			</div>

			<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}			
			>Save Changes</button>
			</div>
		    </div>

		</div>

		:

		<div className="col-12 pt-3">	

			<div className="form-group">			
			<label className="form-label">Title</label>			
			<input type="text" className={`form-control ${errors.title ? 'error': ''} `} id="title" name="title" 
			value={data.title}
			onChange={handleChange} />
			{errors.title && 
				<div className="error-msg">{errors.title}</div>    
			} 			
			</div>

			<div className="form-group">			
			<label className="form-label">Identity</label>			
			<input type="text" className={`form-control ${errors.identity ? 'error': ''} `} id="identity" name="identity" 
			value={data.identity}
			onChange={handleChange} />
			{errors.identity && 
				<div className="error-msg">{errors.identity}</div>    
			} 			
			</div>

			<div className="form-group">
			<label className="form-label">Content</label>			
			<textarea rows={15} className={`form-control ${errors.content ? 'error': ''} `} id="content" name="content" 
			value={data.content}
			onChange={handleChange} />
			{errors.content && 
				<div className="error-msg">{errors.content}</div>    
			} 
			</div>

			<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select ${errors.status ? 'error': ''} `} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option></option>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>
			{errors.status && 
				<div className="error-msg">{errors.status}</div>    
			} 
		    </div>

			<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}			
			>Save Changes</button>
			</div>
		    </div>

		</div>
	} 	

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default Form;

