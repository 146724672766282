import React, { useState, useEffect } from 'react';
import { Link,Navigate, useParams } from 'react-router-dom'; 
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { collection, doc, getDoc, addDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../../config/firebase-config";
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta';

const BannerForm = (props)=>{ 	

	const params = useParams();	
    const catid  = (params.catid) ? params.catid : ''
	const id     = (params.id) ? params.id : ''

	const metaData = {
		meta_title			: (id) ? 'Edit Banner' : 'Add Banner',
		meta_description	: '',
		meta_keywords		: '',
		msg			        : (id) ? 'Data updated successfully' : 'Data added successfully',
	} 

	const __data = {
        banner_image: '',
		banner_text: '',       
        sort_order: 0,
        status: 1,		
	}
	const __errors = {
		banner_image: '',		
	}

	const [data,set_data]  									= useState(__data) 
	const [catData,set_catData]  							= useState('') 
    const [disablebutton, set_disablebutton] 				= useState(false);   	
	const [errors,set_errors]     			   				= useState(__errors)     
    const [progresspercent, setProgresspercent]             = useState(0);
	const [isUpdated, setIsUpdated]                         = useState(false);

	const MySwal = withReactContent(Swal)

	useEffect(() => {
		if(id){
			fetchData(id)
		} 
		if(catid){
			fetchCatData(catid)
		} 
		       
	},[]); 

    const fetchData = async (id)=>{
        const docRef  = doc(db, "banners", id);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_data({
				...item,
				id:docSnap.id
			})			
		} 		
    }
	const fetchCatData = async (catid)=>{
        const docRef  = doc(db, "banner_category", catid);
		const docSnap = await getDoc(docRef);
		if (docSnap.exists()) {
			const item = docSnap.data()
			set_catData({
				...item,
				id:docSnap.id
			})			
		} 		
    }

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_data({...data, [field_name]: field_value})
	}

	const upload_file = (e)=>{	
        const promise = new Promise( (resolve,reject)=>{       
            const file = e.target[0]?.files[0]
            if(!file){
                resolve('') 
            } 
            const storageRef = ref(storage, `assets/upload/banners/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setProgresspercent(progress);
                },
                (error) => {
                    alert(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => { 
                        resolve(downloadURL) 
                        setProgresspercent(0);
                    });
                }
            );
        })
        return promise        
	}

	const deleteFile = (fileUrl,id)=>{
        MySwal.fire({
            title: 'Are you sure?',  
            text: 'You want to delete selected item',                 
            showCancelButton: true,                 
            cancelButtonText: 'No',
            cancelButtonColor: '#3085d6',  
            confirmButtonText: 'Yes', 
            confirmButtonColor: '#d33'
        }).then((result) => {
            
            if(result.isConfirmed) {
                const desertRef = ref(storage, fileUrl);        
                deleteObject(desertRef).then( async () => {            
                    const docRef = doc(db, "banners", id);                
                    await updateDoc(docRef, {
                        banner_image: '',                
                    }); 
                    set_data({...data, ['banner_image']: ''})                    
                    MySwal.fire({icon: 'success', text:'item deleted succsessfully', confirmButtonColor: '#3085d6'})       

                })                  
            }
                       
        })
    }

	const validateForm = ()=>{		
		let errors                = {};  
		let isValid               = true;  

		if(!catid){
			isValid 			   = false;
			errors.catid           = 'catid is required';
		}	        
		set_errors(errors);	
		return isValid;		 
	}

	const handleSubmit = async(e)=>{
		e.preventDefault();   
		if( validateForm() ){  

			set_disablebutton(true)

            const promise = upload_file(e)                    
            promise.then( async(uploadedFile)=>{                
                
				if(uploadedFile){
                    set_data({...data, ['banner_image']: uploadedFile})					
                }  

				if(!uploadedFile && !data.banner_image){
                    set_errors({['banner_image'] : 'Banner image is required'})
					set_disablebutton(false)			
					return false
                }  
				else{

					if(data.id){
						const docRef = doc(db, "banners", data.id);                
						await updateDoc(docRef, {
							banner_image: (uploadedFile) ? uploadedFile : data.banner_image,
							banner_text: data.banner_text,                   
							catid: catid, 
							sort_order: parseInt(data.sort_order),     
							status: parseInt(data.status), 
							modified_date: new Date(),        
						}); 		
					}
					else{					
						const docRef = await addDoc(collection(db, "banners"), {
							banner_image: (uploadedFile) ? uploadedFile : data.banner_image,
							banner_text: data.banner_text,                   
							catid: catid,   
							sort_order: parseInt(data.sort_order),     
							status: parseInt(data.status),   
							create_date: new Date(),  
							modified_date: new Date(),    
						});							
					}
	
					setIsUpdated(true) 
					set_disablebutton(false)				
					MySwal.fire({icon: 'success', text:metaData.msg, confirmButtonColor: '#3085d6'}) 
				}
            })
            
		}			
	}
    
	if(isUpdated){			
		return <Navigate  to={`/banners/${catid}`} />			
	}	

    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>{metaData.meta_title}</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>   
		  <li className="breadcrumb-item"><Link to="/banner-category">Banner Category</Link></li>    
		  <li className="breadcrumb-item"><Link to={`/banners/${catid}`}>Banners</Link></li>              
          <li className="breadcrumb-item active">{metaData.meta_title}</li>
        </ol>
      </nav>
    </div>
	<section className="section">
	<div className="row"> 
	<div className="col-lg-8">
	<div className="card">
	<div className="card-body">
	<form name="userProfileForm" id="userProfileForm" method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
	<div className="col-12 pt-3">
		
		<div className="form-group">
			<label className="form-label">
				Banner Image 
				<small style={{color:'#aaa'}}>
					({`Size : ${catData.width}X${catData.height} pixel`})
				</small>
			</label>			
			{data.banner_image &&                    
				<div>
				<input type='file' style={{display:"none"}} /> 
				<div className='imagebox'>     
				<img src={data.banner_image} alt='' height={100} />
				<i className="bi bi-trash" onClick={ ()=>deleteFile(data.banner_image,data.id) }></i>
				</div> 
				</div>
			}
			{!data.banner_image &&
				<div>
					<input type='file' /> 	
					{errors.banner_image && 
						<div className="error-msg">{errors.banner_image}</div>    
					} 				
					<div className="className mt-1" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
					<div className="progress-bar" style={{ width: `${progresspercent}%` }}>{progresspercent}%</div>
					</div>
				</div>
			}			
		</div>

		<div className="form-group">
			<label className="form-label">Banner Text</label>			
			<textarea rows={10} className={`form-control ${errors.banner_text ? 'error': ''} `} id="banner_text" name="banner_text" 
			value={data.banner_text}
			onChange={handleChange} />
			{errors.banner_text && 
				<div className="error-msg">{errors.banner_text}</div>    
			} 
		</div>

		<div className="form-group">
			<label className="form-label">Sort order</label>			
			<input type="number" className={`form-control`} id="sort_order" name="sort_order" 
			value={data.sort_order}
			onChange={handleChange} />			
		</div>

		<div className="form-group">
			<label className="form-label">Status</label>			
			<select className={`form-select`} id="status" name="status" 
			value={data.status}
			onChange={handleChange}>
			<option></option>
			<option value={1}>Active</option>
            <option value={0}>InActive</option>
			</select>			
		</div>

		<div className="form-group">
			<label className="col-md-4 col-lg-3 col-form-label"></label>
			<div className="col-md-8 col-lg-9">
			<button 
			type="submit"                
			className={`btn btn-primary`}
			disabled={disablebutton}
			>Save Changes</button>
			</div>
		</div>

		</div>

	</form>
	</div>
	</div>
	</div>
	</div>
	</section>
	</main>
	</>
    );
 
}

export default BannerForm;

