import React, { useState, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Parser from 'html-react-parser';

import { db } from "../../../config/firebase-config";
import { query, collection, deleteDoc, doc, getDocs, updateDoc, getCountFromServer, where, orderBy, limit, limitToLast, startAfter, endBefore} from "firebase/firestore";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import Meta from '../../common/Meta'; 

const List = (props)=>{  
	
	const metaData = {
		meta_title			: 'Testimonial',
		meta_description	: '',
		meta_keywords		: '',
	} 

	const queryParams   = new URLSearchParams(window.location.search) 
    const item_per_page = 10
		
	const __filterData = {
        name: (queryParams.get('name')) ? (queryParams.get('name')) : '',
		designation: (queryParams.get('designation')) ? (queryParams.get('designation')) : '',
        status: (queryParams.get('status')) ? (queryParams.get('status')) : '',
	}

	const [filterData,set_filterData] 		= useState(__filterData) 	
	const [data,set_data]             		= useState([]) 
	const [total,set_total] 		        = useState(0)
	const [page, setPage]                   = useState(1);		

	useEffect(() => {
        fetchData()
	},[]); 

	const MySwal = withReactContent(Swal)

	const gueryFilter = (item_per_page)=>{ 
		    
		let q = query(collection(db, "testimonial"));		
		if(filterData.name){
			q = query(q, where("name", ">=", filterData.name));	
			q = query(q, where("name", "<=", filterData.name));	
		}
		if(filterData.designation){
			q = query(q, where("designation", "==", filterData.designation));			
		}
		if(filterData.status){
			q = query(q, where("status", "==", parseInt(filterData.status)));
		}	
		return q	
	}

	const fetchData = async ()=>{ 
		let q = gueryFilter();		
		    q = query(q, orderBy('name','asc'), limit(item_per_page));	
        const querySnapshot = await getDocs(q);	
        let result = querySnapshot.docs.map((doc) => ({ 
            ...doc.data(), 
            id: doc.id,             
        }));   
		let q2 = gueryFilter();
		    q2 = query(q2, orderBy('name','asc'));		
		const totalSnapshot = await getCountFromServer(q2);		
        const total         = totalSnapshot.data().count
		
        set_data(result)
		set_total(total)		
		setPage(1)
    }

	const showNext = async (item) => {		
        if(data.length === 0) {
        	alert("Thats all we have for now !")
        } 
		else{
			
			let q = gueryFilter();		
		    q = query(q, orderBy('name','asc'), limit(item_per_page), startAfter(item.name));	
			const querySnapshot = await getDocs(q);	
			let result = querySnapshot.docs.map((doc) => ({ 
				...doc.data(), 
				id: doc.id,             
			}));  
			
            set_data(result);
            setPage(page + 1)
        }
    };

	const showPrevious = async (item) => {
		let q = gueryFilter();		
		q = query(q, orderBy('name','asc'), limitToLast(item_per_page), endBefore(item.name));	
		const querySnapshot = await getDocs(q);	
		let result = querySnapshot.docs.map((doc) => ({ 
			...doc.data(), 
			id: doc.id,             
		}));   

		set_data(result);
        setPage(page - 1)
    };

	const handleCheckall = (e)=>{
		let inputs = document.querySelectorAll('.selected-chk');  	
		if(e.target.checked){			
			for (var i = 0; i < inputs.length; i++) {   
				inputs[i].checked = true;   
			}   
		} 
		else{
			for (var j = 0; j < inputs.length; j++) {   
				inputs[j].checked = false;   
			}   
		}	
	}  

	const handleChange = (e)=>{	
		const field_name  = e.target.name;
		const field_value = e.target.value;
		set_filterData({...filterData, [field_name]: field_value})
	}	

	const handleFilter = (e)=>{
		e.preventDefault();	
		setBrowserUrl()	
		fetchData()	
	}

	const setBrowserUrl = ()=>{	
		const location = new URL(window.location);
		location.search="";
		if(filterData.name){
			location.searchParams.set('name', filterData.name);
		}
		if(filterData.designation){
			location.searchParams.set('designation',filterData.designation);
		}	
		if(filterData.status){
			location.searchParams.set('status', filterData.status);
		}	
		window.history.pushState({},'', location);
	}

	const confirmDelete = async (id)=>{	
		MySwal.fire({
			//icon: 'warning',
			title: 'Are you sure?',
			text: "You want to delete this item!",			
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
			}).then( async (result) => {
			if (result.isConfirmed) {
				
				const docRef = doc(db, "testimonial", id);								
				await deleteDoc(docRef);

				setBrowserUrl()	          
				fetchData()

				MySwal.fire({
					icon: 'success',
					title: 'Deleted!',
					text: "Selected item has been deleted."
				})		
			}
		})	
  	} 
	const applyToSelected = (action)=>{
		let selected_ID = [];
		let inputs      = document.querySelectorAll('.selected-chk:checked');  	
		selected_ID     = Array.from(inputs).map(x => x.value)
	
		if(action && inputs.length){
			MySwal.fire({
				//icon: 'warning',
				title: 'Are you sure?',
				text: "You want to perform selected action",			
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, do it!'
			}).then( async (result) => {
				if (result.isConfirmed) {					
					
					if(action==='Enable'){		
						const promises  = [];    		
						
						selected_ID.map( (id,key)=>{
							const item_promise = new Promise( async(resolve,reject)=>{
								const docRef = doc(db, "testimonial", id);
								await updateDoc(docRef, {
									status: 1
								});
								resolve(true)
							})
							promises.push(item_promise) 							
						})
						
						Promise.all(promises).then((values)=>{							
							setBrowserUrl()				
							fetchData()					
							MySwal.fire({
								icon: 'success',
								title: 'Updated!',
								text: 'Selected item has been updated successfully.'
							})	
						})							
						
					}
					else if(action==='Disable'){
						const promises  = [];    		
						
						selected_ID.map( (id,key)=>{
							const item_promise = new Promise( async(resolve,reject)=>{
								const docRef = doc(db, "testimonial", id);
								await updateDoc(docRef, {
									status: 0
								});
								resolve(true)
							})
							promises.push(item_promise) 							
						})
						
						Promise.all(promises).then((values)=>{							
							setBrowserUrl()				
							fetchData()					
							MySwal.fire({
								icon: 'success',
								title: 'Updated!',
								text: 'Selected item has been updated successfully.'
							})	
						})								
					}
					else if(action==='Delete'){
						const promises  = [];    		
						
						selected_ID.map( (id,key)=>{
							const item_promise = new Promise( async(resolve,reject)=>{

								const docRef = doc(db, "testimonial", id);								
								await deleteDoc(docRef);

								resolve(true)
							})
							promises.push(item_promise) 							
						})
						
						Promise.all(promises).then((values)=>{							
							setBrowserUrl()				
							fetchData()					
							MySwal.fire({
								icon: 'success',
								title: 'Updated!',
								text: 'Selected item has been Deleted successfully.'
							})	
						})							
					}
				}
			})
		}	
  	}  


	let total_page          = Math.ceil(total/item_per_page);  
	let start_text          = (total) ? ((page - 1) * item_per_page) + 1 : 0;
    let end_text            = (((page - 1) * item_per_page) > (total - item_per_page)) ? total : (((page - 1) * item_per_page) + item_per_page);
    let display_text        = `Showing ${start_text} to ${end_text}  of <strong>${total}</strong> results`;
	let sl_no               = (total) ? ((page - 1) * item_per_page) + 1 : 1;
		
    return ( 
	<>		
	<Meta metaData={metaData} />
    <HelmetProvider>
    <Helmet>		
    </Helmet>				
    </HelmetProvider>

	<main id="main" className="main">
	<div className="pagetitle">
      <h1>Testimonial</h1>
      <nav>
        <ol className="breadcrumb">
          <li className="breadcrumb-item"><Link to="/">Home</Link></li>          
          <li className="breadcrumb-item active">Testimonial</li>
        </ol>
      </nav>
    </div>
	<section className="section">
		<div className="row">
			<div className="col-lg-12">
				<div className="card">
				
				<div className="card-header">
				<div className='pull-right'> 
				<Link to="/add-testimonial" className="p-1"><button type="button" className="btn btn-primary">+ Add New</button></Link>
				</div>
				</div>
				
				<form id="frmFilter" name="frmFilter" method="post" encType="multipart/form-data" onSubmit={handleFilter}>				
				<div className="card-body table-responsive">					         
				<table className="table table-hover">
					<thead>
					<tr>
					    <th><input className="form-check-input checkall" type="checkbox" onClick={handleCheckall} /></th>
						<th scope="col" width="5%">SL</th>
						<th scope="col">Image</th>
						<th scope="col">Name</th>
						<th scope="col">Designation</th>
						<th scope="col">Status</th>
						<th scope="col" width="10%" className="text-center">Action</th>						
					</tr>
					<tr>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>&nbsp;</td>
						<td>
						<input className="form-control" type="text" 
						name="name" 
						value={filterData.name}  
						onChange={handleChange} />	
						</td>
						<td>
						<input className="form-control" type="text" 
						name="designation" 
						value={filterData.designation}  
						onChange={handleChange} />
						</td>

						<td>
						<select className="form-select" name="status" value={filterData.status} onChange={handleChange}>
							<option value=""></option>
							<option value="0">Inactive</option>
							<option value="1">Active</option>
						</select>
						</td>

						<td className="text-center">
						<button type="submit" name="OkFilter" id="OkFilter" className="btn  btn-primary">Filter</button>
						</td>
					</tr>
					</thead>
					<tbody>
					{data &&
						data.map((doc,index) => ( 
						<tr key={index+1}>
							<td>
							<input type="checkbox" className="form-check-input selected-chk" name="id[]" value={doc.id} />							
							</td>
							<td>{sl_no++}</td>
							<td>
							{doc.profile_image ? 
								<img src={doc.profile_image} height={75} />
								: 
								<i className="bi bi-camera" style={{fontSize:'40px'}}></i>
							}  
							</td>
							<td>{Parser(doc.name)}</td>
							<td>{doc.designation}</td>
							<td>
								{doc.status===0 &&
									<span className="badge rounded-pill bg-danger">InActive</span>
								}
								{doc.status===1 &&
									<span className="badge rounded-pill bg-success">Active</span>
								}
							</td>
							<td className="text-center">
							    
								<Link to={`/edit-testimonial/${doc.id}`}>
								<button type="button" className="btn btn-info btn-sm" title='Edit'>
								<i className="bi bi-pencil-square"></i>
								</button>
								</Link>
								
								<button type="button" className="btn btn-danger btn-sm" title='Delete' onClick={()=>confirmDelete(doc.id)}>
								<i className="bi bi-trash"></i>
								</button>
								
							</td>
						</tr> 						
                       ))}  									           
					</tbody>
				</table>
				{data && data.length>=1 &&		
				<div className="row">
					<div className="col-lg-3">
						<select name="action" id="frm_action" className="form-select">
							<option value="">Choose an action...</option>
							<option value="Delete">Delete</option>
							<option value="Enable">Enable</option>
							<option value="Disable">Disable</option>
						</select>
					</div>
					<div className="col-lg-9">
						<button 
						type="button" 
						onClick={()=>applyToSelected(document.getElementById('frm_action').value)} 
						className="btn btn-block btn-primary">Apply to selected</button>
					</div>
				</div>  
                }
				{data && data.length===0 &&						
					<div className='text-center'>No record Found!</div>						
  				} 			
				</div>
				</form>
				
				{total_page>1 && 
				<>
					<div className="card-body table-responsive">
						
					<div className="float-right">
					<span className="p-3">{Parser(display_text)}</span>
					<div className="btn-group">
                    
					{page === 1 ? 
						<button type="button" className="btn btn-secondary" disabled>Previous</button>
						: 
						<button type="button" className="btn btn-primary" onClick={() => showPrevious(data[0]) } >Previous</button>
					}

					{page < total_page ? 
						<button type="button" className="btn btn-primary" onClick={() => showNext(data[data.length - 1])}>Next</button>
						: 
						<button type="button" className="btn btn-secondary" disabled>Next</button>						
					}	

					</div>
					</div>
					</div>
					</>
				}				
                
			</div>    
			</div>
		</div>		
		</section>
	</main>
	</>
    );
 
}

export default List;

